.character {
  width: 50px;
  border: none;
  font-size: 50px;
  font-weight: bold;
  border-radius: 8px;
  color: #272729;
  background-color: #f6f5fa;
  box-shadow: 0 2px 0 #e4e2f5;
}
