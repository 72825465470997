.no_caret::before {
    display: none;
}

.no_caret::after {
    display: none;
}

.shadow-card {
    transition: box-shadow 0.3s ease-in-out;
    box-shadow: none;
    border-radius: 5px;
}

.shadow-card:hover {
    box-shadow: rgba(60, 72, 88, 0.15) 0px 4px 30px 8px;
}